import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';

const AccordionItem = ({ title, onDelete, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border rounded-md mb-2">
            <div className="flex justify-between items-center p-4">
                <button
                    className="flex-grow text-left focus:outline-none"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {title}
                </button>
                <button
                    className="ml-4 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 text-sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                >
                    Delete
                </button>
            </div>
            {isOpen && <div className="p-4 border-t">{children}</div>}
        </div>
    );
};

const UserQuizzes = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userId = localStorage.getItem('uid');

    const fetchQuizzes = async () => {
        try {
            const response = await axios.get(`${serverURL}/api/user-quizzes/${userId}`);
            setQuizzes(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch quizzes. Please try again later.');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQuizzes();
    }, [userId]);

    const handleDeleteQuiz = async (quizId) => {
        if (window.confirm('Are you sure you want to delete this quiz?')) {
            try {
                await axios.delete(`${serverURL}/api/user-quizzes/${quizId}`);
                // Refresh the quiz list after deletion
                fetchQuizzes();
            } catch (err) {
                setError('Failed to delete quiz. Please try again.');
            }
        }
    };

    if (loading) return <div>Loading quizzes...</div>;
    if (error) return <div>{error}</div>;
    if (quizzes.length === 0) return <div>No quizzes found.</div>;

    return (
        <div className="space-y-4">
            {quizzes.map((quiz) => (
                <AccordionItem
                    key={quiz._id}
                    title={`${quiz.courseTopic ? quiz.courseTopic + " - " : ""} ${quiz.subtopic} (${quiz.level})`}
                    onDelete={() => handleDeleteQuiz(quiz._id)}
                >
                    <div className="space-y-2">
                        {quiz.questions.map((question, qIndex) => (
                            <div key={qIndex} className="border p-4 rounded-md">
                                <p className="font-semibold">Q: {question.question}</p>
                                <p className="mt-2">A: {question.answer}</p>
                            </div>
                        ))}
                    </div>
                    <button
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={() => {/* Implement take quiz functionality */ }}
                    >
                        Take Quiz
                    </button>
                </AccordionItem>
            ))}
        </div>
    );
};

export default UserQuizzes;