import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { serverURL } from '../constants';
import axios from 'axios';
import Loader from '../components/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navbar } from 'flowbite-react';
import MultipleFileUpload from './MultipleFileUpload';
const ModalButton = styled.button`
  background-color: #135D66;
  color: white;
  padding: 7px 20px;
  border: none;
  margin: 0px 8px;
  cursor: pointer;
  font-size: 16px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 1000px;
  height: 80vh;
  display: flex;
  overflow: hidden;
`;

const CloseButton = styled.button`
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const LevelButton = styled.button`
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: ${props => props.selected ? '#135D66' : 'white'};
  color: ${props => props.selected ? 'white' : 'black'};
  cursor: pointer;
`;

const QuestionTypeLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GenerateButton = styled.button`
  background-color: #135D66;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const QuizDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const QuestionCard = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
`;

const AnswerCard = styled.div`
  background-color: #e6f7ff;
  border-radius: 5px;
  padding: 15px;
  margin-top: 5px;
`;

const ShowAnswerButton = styled.button`
  background-color: #135D66;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
`;

const NextButton = styled.button`
  background-color: #135D66;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
`;

const ResetButton = styled(NextButton)`
  background-color: #f44336;
  margin-left: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

const RequiredField = styled.span`
  color: red;
  margin-left: 5px;
`;

const Sidebar = styled.div`
  width: 200px;
  padding: 20px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
`;

const SidebarItem = styled.div`
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${props => props.active ? '#135D66' : 'white'};
  color: ${props => props.active ? 'white' : 'black'};
  border-radius: 5px;
`;

const CopyButton = styled.button`
  background-color: #135D66;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 10px;
`;
const Section = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
      width: 100%;
    margin: auto;
`;
const Quiz = ({ questions, onReset, onRegenerate, onSave }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);

    const handleNextQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
        setShowAnswer(false);
    };

    const toggleAnswer = () => {
        setShowAnswer(!showAnswer);
    };

    const copyQuestionAndAnswer = () => {
        const question = removeAsterisks(questions[currentQuestionIndex].question);
        const answer = removeAsterisks(questions[currentQuestionIndex].answer);
        const textToCopy = `Question: ${question}\n\nAnswer: ${answer}`;
        navigator.clipboard.writeText(textToCopy)
            .then(() => toast.success('Copied'))
            .catch(err => {
                console.error('Failed to copy text: ', err);
                toast.error('Failed to copy. Please try again.');
            });
    };

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <>
            <ModalContent>
                <Sidebar>
                    <h3>Questions</h3>
                    {questions.map((q, index) => (
                        <SidebarItem
                            key={index}
                            active={index === currentQuestionIndex}
                            onClick={() => { setCurrentQuestionIndex(index); setShowAnswer(false); }}
                        >
                            Question {index + 1}
                        </SidebarItem>
                    ))}
                </Sidebar>
                <MainContent>
                    <QuizDisplay>
                        <h2>Generated Quiz</h2>
                        <QuestionCard>
                            <h3>Question {currentQuestionIndex + 1} of {questions.length}</h3>
                            <div dangerouslySetInnerHTML={{ __html: removeAsterisks(currentQuestion.question) }} />
                            <ShowAnswerButton onClick={toggleAnswer}>
                                {showAnswer ? 'Hide Answer' : 'Show Answer'}
                            </ShowAnswerButton>
                            <CopyButton onClick={copyQuestionAndAnswer}>
                                Copy
                            </CopyButton>
                            {showAnswer && (
                                <AnswerCard>
                                    <div dangerouslySetInnerHTML={{ __html: removeAsterisks(currentQuestion.answer) }} />
                                </AnswerCard>
                            )}
                        </QuestionCard>
                        <div>
                            <NextButton onClick={handleNextQuestion}>
                                Next Question
                            </NextButton>
                            <ResetButton onClick={onReset}>
                                Reset
                            </ResetButton>
                            <NextButton className='mx-4' onClick={onRegenerate}>
                                Regenerate
                            </NextButton>
                            <NextButton onClick={onSave}>
                                Save
                            </NextButton>
                        </div>
                    </QuizDisplay>
                </MainContent>
            </ModalContent>
        </>
    );
};

const MemoizedQuiz = React.memo(Quiz);

const removeAsterisks = (text) => {
    return text.replace(/\*/g, '');
};

const QuestionModal = ({ courseTopic }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [pdfContent, setPdfContent] = useState('');

    const [questionTypes, setQuestionTypes] = useState({
        longAnswer: false,
        multipleChoice: false,
        trueFalse: false,
        essay: false
    });
    const [mimicQuestion, setMimicQuestion] = useState('');
    const [generatedQuiz, setGeneratedQuiz] = useState(null);
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [selectedSubtopic, setSelectedSubtopic] = useState('');
    const subtopicsFromStorage = localStorage.getItem('courseSubtopics') || '';
    const subtopics = subtopicsFromStorage.split(',');
    console.log(subtopics);

    const toggleModal = () => setIsOpen(!isOpen);

    const handleQuestionTypeChange = (type) => {
        setQuestionTypes(prev => ({ ...prev, [type]: !prev[type] }));
    };

    const handleGenerateQuiz = async () => {

        if (!selectedLevel) {
            setValidationError("Please select a level");
            return;
        }
        if (!Object.values(questionTypes).some(Boolean)) {
            setValidationError("Please select at least one question type");
            return;
        }
        setValidationError(null);
        setIsLoading(true);
        setError(null);
        try {
            const useMimicMode = mimicQuestion && mimicQuestion.trim() !== '';

            // Prepare the JSON structure
            const jsonStructure = JSON.stringify({
                subtopic: selectedSubtopic,
                level: selectedLevel,
                questionTypes: Object.keys(questionTypes).filter(key => questionTypes[key]),
            });
            // Prepare the prompt based on whether we're using mimic mode or not
            let prompt;
            if (useMimicMode) {
                prompt = `Generate a strictly ${Object.keys(questionTypes).filter(key => questionTypes[key])} types of questions that mimic the structure and style of the following question, but with different content. If multiple question types are given then respond by dividing questions equally for each type. Provide an answer for each generated question. Generate between 5 and 20 questions:
        Original question to mimic: "${mimicQuestion}". Please provide the questions and answers in JSON format. Each question should be an object with 'question' and 'answer' properties. Return an array of these objects. Do not include any additional text or explanations. Strictly do not write. Do not mention 'json' as response language type in response. Use core json format only to reply. For multiple choice questions strictly include at least four options a,b,c,d to choose an answer from under question key of response json. Strictly use only this standard format for each array { "question": "question text? <br> a) option one <br> b) option two<br> c) option three <br> d) option four", "answer": "b) option two" }. Questions should be  related to ${mimicQuestion}. Do not use double quotes for nouns, use single quotes for nouns instead. For essay questions, generate questions that require students to respond with full essays, not just paragraphs. The AI model should provide a comprehensive essay outline or key points as the answer for essay questions.Do not mention 'json' as response language type in response. Use core json format only to reply. For multiple choice questions strictly include at least four options a,b,c,d to choose an answer from under question key of response json. Strictly use only this standard format for each array { "question": "question text? <br> a) option one <br> b) option two<br> c) option three <br> d) option four", "answer": "b) option two" }. Strictly use <sup> element to show power of numbers. Please pay special attention to study material or assignments uploaded by user. If pdf content text is not blank then focus to create quiz by maximizing questions from the important topics from the pdf. Here is the pdf text: ` + pdfContent + ": pdf text ends here.Please return the JSON response without any formatting or code blocks.";
            } else {
                prompt = `Generate a strictly ${Object.keys(questionTypes).filter(key => questionTypes[key])} types of quiz with their answers. Strictly do not provide answer field empty or null. Strictly do not include *(asterisk) anywhere in the response use <b> for bold, follow html tags for element styling. Generate between 5 and 20 questions based on the following parameters:
        ${jsonStructure} Please provide the questions and answers in JSON format. Each question should be an object with 'question' and 'answer' properties. Return an array of these objects. Do not include any additional text or explanations. Strictly do not write. Do not mention 'json' as response language type in response. Use core json format only to reply. For multiple choice questions strictly include at least four options a,b,c,d to choose an answer from under question key of response json. Strictly use only this standard format for each array { "question": "question text? <br> a) option one <br> b) option two<br> c) option three <br> d) option four", "answer": "b) option two" }. Questions should be  related to '${selectedSubtopic}'. Do not use double quotes for nouns, use single quotes for nouns instead. For essay questions, generate questions that require students to respond with full essays, not just paragraphs. The AI model should provide a comprehensive essay outline or key points as the answer for essay questions.Do not mention 'json' as response language type in response. Use core json format only to reply. For multiple choice questions strictly include at least four options a,b,c,d to choose an answer from under question key of response json. We may click regenerate quiz so make sure it always returns an unique set and always shuffle questions strictly. Strictly use only this standard format for each array { "question": "question text? <br> a) option one <br> b) option two<br> c) option three <br> d) option four", "answer": "b) option two" }.Strictly use <sup> element to show power of numbers.Please pay special attention to study material or assignments uploaded by user. If pdf content text is not blank then focus to create quiz by maximizing questions from the important topics from the pdf. Here is the pdf text: ` + pdfContent + ": pdf text ends here.Please return the JSON response without any formatting or code blocks.";
            }

            const postURL = serverURL + '/api/gpt-prompt';
            const res = await axios.post(postURL, { prompt: prompt });

            const generatedQuiz = JSON.parse(res.data.generatedText);
            console.log(generatedQuiz);

            if (Array.isArray(generatedQuiz) && generatedQuiz.length > 0) {
                setGeneratedQuiz(generatedQuiz);
            } else {
                throw new Error('No quiz questions generated');
            }
        } catch (error) {
            console.error('Error generating quiz:', error);
            setGeneratedQuiz(null);
            setError('Failed to generate quiz. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleResetQuiz = () => {
        setGeneratedQuiz(null);
        setSelectedLevel(null);
        setQuestionTypes({
            longAnswer: false,
            multipleChoice: false,
            trueFalse: false,
            essay: false
        });
        setMimicQuestion('');
        setValidationError(null);
        setSelectedSubtopic('');
    };
    const regenerateQuiz = () => {
        setGeneratedQuiz(null);
        handleGenerateQuiz()
    };
    const saveQuiz = async () => {
        if (!generatedQuiz) {
            toast.error('No quiz to save. Please generate a quiz first.');
            return;
        }

        try {
            const userId = localStorage.getItem('uid'); // Get user ID from session storage

            const response = await axios.post(`${serverURL}/api/save-user-quiz`, {
                userId,
                courseTopic,
                subtopic: selectedSubtopic,
                level: selectedLevel,
                questions: generatedQuiz
            });

            if (response.data.success) {
                toast.success('Quiz saved successfully!');
            } else {
                throw new Error('Failed to save quiz');
            }
        } catch (error) {
            console.error('Error saving quiz:', error);
            toast.error('Failed to save quiz. Please try again.');
        }
    };
    return (
        <>
            <Navbar.Link onClick={toggleModal} className='text-center border-b-0 text-white  font-normal mb-1 mt-1 bg-black dark:text-black dark:bg-white  hover:bg-black dark:hover:bg-white md:dark:hover:bg-white md:hover:bg-black hover:text-white md:hover:text-white dark:hover:text-black dark:md:hover:text-black' style={{ paddingLeft: '15px', paddingRight: '15px', paddingBottom: '10px', paddingTop: '10px' }}>Play Quiz</Navbar.Link>
            {isOpen && (
                <ModalOverlay>
                    <ModalContent>
                        <MainContent>
                            <Section>
                                <CloseButton onClick={toggleModal} aria-label="Close modal">&times;</CloseButton>
                                {generatedQuiz ? (
                                    <MemoizedQuiz questions={generatedQuiz} onRegenerate={regenerateQuiz} onReset={handleResetQuiz} onSave={saveQuiz} />
                                ) : (
                                    <>
                                        <h2>Generate Quiz for: {courseTopic}</h2>
                                        <div className='my-2'>
                                            <h3>Enter Subtopic:<RequiredField>*</RequiredField></h3>
                                            <input
                                                style={{ width: '100%' }}
                                                type="text"
                                                value={selectedSubtopic}
                                                onChange={(e) => setSelectedSubtopic(e.target.value)}
                                                placeholder="Enter a subtopic for quiz generation"
                                                aria-label="Enter subtopic"
                                            />
                                        </div>
                                        <div className='my-2' >
                                            <h3>Select Level:<RequiredField>*</RequiredField></h3>
                                            {['Practice quiz', 'IB', 'AP', 'A-level', 'University Level'].map(level => (
                                                <LevelButton className='mr-3'
                                                    key={level}
                                                    selected={selectedLevel === level}
                                                    onClick={() => setSelectedLevel(level)}
                                                >
                                                    {level}
                                                </LevelButton>
                                            ))}
                                        </div>
                                        <div className='my-2' >
                                            <h3>Question Types:<RequiredField>*</RequiredField></h3>
                                            <QuestionTypeLabel>
                                                <input
                                                    type="checkbox"
                                                    checked={questionTypes.longAnswer}
                                                    onChange={() => handleQuestionTypeChange('longAnswer')}
                                                />
                                                Long-Answer questions
                                            </QuestionTypeLabel>
                                            <QuestionTypeLabel>
                                                <input
                                                    type="checkbox"
                                                    checked={questionTypes.multipleChoice}
                                                    onChange={() => handleQuestionTypeChange('multipleChoice')}
                                                />
                                                Multiple Choice questions
                                            </QuestionTypeLabel>
                                            <QuestionTypeLabel>
                                                <input
                                                    type="checkbox"
                                                    checked={questionTypes.trueFalse}
                                                    onChange={() => handleQuestionTypeChange('trueFalse')}
                                                />
                                                True/False questions
                                            </QuestionTypeLabel>
                                            <QuestionTypeLabel>
                                                <input
                                                    type="checkbox"
                                                    checked={questionTypes.essay}
                                                    onChange={() => handleQuestionTypeChange('essay')}
                                                />
                                                Essay questions
                                            </QuestionTypeLabel>
                                        </div>
                                        <div className='my-2'>
                                            <h3>Question Mimic (Optional):</h3>

                                            <input
                                                style={{ width: '100%' }}
                                                type="text"
                                                value={mimicQuestion}
                                                onChange={(e) => setMimicQuestion(e.target.value)}
                                                placeholder="Enter a sample question to mimic..."
                                                aria-label="Sample question to mimic"
                                            />
                                            <p style={{ fontSize: '12px', color: '#666', marginBottom: '5px' }}>
                                                When you enter a question to mimic, all other options will not be operational.
                                                The quiz will be generated based solely on mimicking this question's structure and style.
                                            </p>
                                        </div>
                                        <div className='my-4'>
                                            <MultipleFileUpload
                                                onFilesProcessed={(content) => setPdfContent(content)}
                                            />
                                        </div>
                                        {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
                                        <GenerateButton onClick={handleGenerateQuiz} disabled={isLoading} aria-label="Generate quiz">
                                            {isLoading ? <Loader /> : 'Generate Quiz'}
                                        </GenerateButton>
                                        {error && <ErrorMessage>{error}</ErrorMessage>}
                                    </>
                                )}
                            </Section>
                        </MainContent>

                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

export default QuestionModal;