import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import axios from 'axios';
import { serverURL } from '../constants';
import TaskModal from '../components/TaskModal';
import AiRecommendationsModal from '../components/AiRecommendationsModal';
import { toast } from 'react-toastify';
import { getCalendarToken } from '../utils';
const CalendarPage = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [aiRecommendations, setAiRecommendations] = useState(null);
    const userId = localStorage.getItem('uid');
    const truncateTitle = (title, maxLength = 20) => {
        return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
    };
    const fetchEvents = async () => {
        try {
            const response = await axios.get(`${serverURL}/api/get-study-plan/${userId}`);
            const formattedEvents = response.data.studyPlan.map(item => ({
                id: item._id,
                title: item.start_time + " " + item.task,
                start_date: item.start_date,
                end_date: item.end_date,
                start_time: item.start_time,
                end_time: item.end_time,

                start: new Date(item.start_date + "T" + item.start_time),
                end: new Date(item.end_date + "T" + item.end_time),
                extendedProps: {
                    status: item.status,
                    progress: item.progress,
                    subject: item.subject,
                    task: item.task,
                    difficulty: item.difficulty,
                    notes: item.notes,
                    aiChanges: item.aiChanges
                }
            }));
            setEvents(formattedEvents);
            console.log(events);

        } catch (error) {
            console.error('Error fetching events:', error);
            toast.error(error.response.data.message);
        }
    };
    useEffect(() => {
        fetchEvents();
    }, []);
    function calculateEndDate(formData) {
        // Create a new Date object from the datetime-local input value
        const startDate = new Date(formData.datetime);
        const duration = parseInt(formData.duration, 10);
        const unit = formData.unit;

        // Create end date by cloning start date
        const endDate = new Date(startDate);

        // Debug initial values


        // Add duration based on the unit
        switch (unit) {
            case 'minute':
                endDate.setMinutes(startDate.getMinutes() + duration);
                break;
            case 'hour':
                endDate.setHours(startDate.getHours() + duration);
                break;
            case 'day':
                endDate.setDate(startDate.getDate() + duration);
                break;
            default:
                throw new Error(`Invalid unit: ${unit}`);
        }

        return endDate;

    }


    const handleEventUpdate = async (eventId, updates) => {
        let startDate = updates.datetime;
        startDate = new Date(startDate);
        updates.startTime = startDate.toLocaleString();
        let endDate = calculateEndDate(updates);
        updates.endTime = endDate.toLocaleString();

        setIsLoading(true);
        try {
            let response;
            if (eventId === "") {

                response = await axios.post(`${serverURL}/api/calendar/update-task`, {
                    eventId,
                    userId,
                    ...updates
                });
            } else {
                response = await axios.post(`${serverURL}/api/calendar/update-task/` + eventId, {
                    eventId,
                    userId,
                    ...updates
                });
            }

            if (response.data.aiRecommendations) {
                setAiRecommendations(response.data.aiRecommendations);
            }

            await fetchEvents();
            toast.success('Task updated successfully');

        } catch (error) {
            if (error.response && error.response.status === 401) {
                const authSuccessful = await getCalendarToken();
                // If authentication is successful, retry the task update
                if (authSuccessful) {
                    await handleEventUpdate(eventId, updates)
                }
            }
            console.error('Error updating task:', error);
            toast.error('Failed to update task');
        }
        setIsLoading(false);
    };

    const handleEventDelete = async (eventId) => {
        setIsLoading(true);
        const userId = localStorage.getItem('uid');
        try {
            const response = await axios.delete(`${serverURL}/api/calendar/delete-task/${eventId}/${userId}`, {
                data: { eventId }
            });

            if (response.data.adjustments) {
                setAiRecommendations(response.data.adjustments);
            }

            await fetchEvents();
            setIsModalOpen(false);
            toast.success('Task deleted successfully');

        } catch (error) {
            if (error.response && error.response.status === 401) {
                const authSuccessful = await getCalendarToken();
                // If authentication is successful, retry the task update

            }
            console.error('Error deleting task:', error);
            toast.error('Failed to deleting task');
        }
        setIsLoading(false);
    };

    const getEventStatusColor = (status) => {
        const colors = {
            'pending': 'bg-blue-200 border-blue-500',
            'in-progress': 'bg-yellow-200 border-yellow-500',
            'completed': 'bg-green-200 border-green-500',
            'cancelled': 'bg-red-200 border-red-500'
        };
        return colors[status] || colors.pending;
    };
    const deleteSchedule = async () => {
        const toastId = toast.loading("Please wait...");
        try {
            const response = await axios.post(`${serverURL}/api/delete-quiz`, { userId });
            fetchEvents();
            toast.update(toastId, { render: "Your study plan has been deleted", type: "success", isLoading: false, autoClose: 3000 });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            return response.data;

        } catch (error) {
            if (error.response.status === 401) {
                const authSuccessful = await getCalendarToken();
            }
            toast.update(toastId, { render: "Something went wrong, Please try again later", type: "error", isLoading: false, autoClose: 3000 });
            // throw error;
        }
    };
    return (
        <div className='min-h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />

            <div className='flex-1 p-4 bg-gray-50'>
                <div className='max-w-7xl mx-auto'>
                    <div className='mb-4 flex justify-end space-x-4'>
                        <button
                            onClick={() => deleteSchedule(true)}
                            className='bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors'
                        >
                            Remove Schedule
                        </button>
                        <button
                            onClick={() => setIsModalOpen(true)}
                            className='bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors'
                        >
                            Add Task
                        </button>
                    </div>

                    <div className='bg-white rounded-lg shadow-md p-4'>
                        <FullCalendar
                            plugins={[dayGridPlugin]}
                            initialView="dayGridMonth"
                            events={events}
                            eventClick={(info) => {
                                setSelectedEvent(info.event);
                                setIsModalOpen(true);
                            }}
                            eventContent={(eventInfo) => (
                                <div>{truncateTitle(eventInfo.event.title)}</div>
                            )}
                            height="auto"
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,dayGridWeek'
                            }}
                        />
                    </div>
                </div>

                {isLoading && (
                    <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)]  bg-opacity-50 flex items-center justify-center z-50">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                )}

                {isModalOpen && (
                    <TaskModal
                        event={selectedEvent}
                        onClose={() => {
                            setIsModalOpen(false);
                            setSelectedEvent(null);
                        }}
                        onUpdate={handleEventUpdate}
                        onDelete={handleEventDelete}
                    />
                )}

                {aiRecommendations && (
                    <AiRecommendationsModal
                        recommendations={aiRecommendations}
                        onClose={() => setAiRecommendations(null)}
                    />
                )}
            </div>

            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default CalendarPage;