import React, { useState, useEffect } from 'react';

import Header from '../components/header';
import Footers from '../components/footers';
const Update = () => {
    const [claimCount, setClaimCount] = useState(() => {
        const storedCount = localStorage.getItem('claimCount');
        const storedTime = localStorage.getItem('claimCountTime');
        const currentTime = new Date().getTime();

        if (storedCount && storedTime && currentTime - parseInt(storedTime) < 60000) {
            return parseInt(storedCount);
        }
        const newCount = getRandomNumber();
        localStorage.setItem('claimCount', newCount.toString());
        localStorage.setItem('claimCountTime', currentTime.toString());
        return newCount;
    });

    useEffect(() => {
        const updateClaimCount = () => {
            const storedTime = localStorage.getItem('claimCountTime');
            const currentTime = new Date().getTime();

            if (!storedTime || currentTime - parseInt(storedTime) >= 60000) {
                const newCount = getRandomNumber();
                setClaimCount(newCount);
                localStorage.setItem('claimCount', newCount.toString());
                localStorage.setItem('claimCountTime', currentTime.toString());
            }
        };

        const interval = setInterval(updateClaimCount, 1000); // Check every second

        return () => clearInterval(interval);
    }, []);

    function getRandomNumber() {
        return Math.floor(Math.random() * (100 - 30 + 1)) + 30;
    }

    return (
        <div className='min-h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-grow flex items-center justify-center'>
                <div className='w-4/5 max-w-2xl mx-auto py-10 text-center'>
                    <h1 className='text-black dark:text-white font-black text-3xl mb-4'>
                        You Have Claimed Your Luck Today!
                    </h1>
                    <p className='mb-6 dark:text-white'>
                        <strong>May The Learnooza Force Be With You! Anywhere, Anytime!</strong>
                    </p>
                    <div className='mt-3 dark:text-white'>
                        <p> <strong className=''>{claimCount} </strong>
                            people also claimed their Daily Luck just now</p>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Update;