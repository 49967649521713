import { serverURL } from "./constants";
export async function getCalendarToken() {
    const userId = localStorage.getItem('uid'); // Get user ID from session storage

    try {
        const authWindow = window.open(
            `${serverURL}/api/auth?userId=${encodeURIComponent(userId)}`,
            'Auth',
            'width=500,height=600'
        );
        if (!authWindow) {
            return false;
        }

        // Poll to check if the auth window has closed
        const pollTimer = window.setInterval(() => {
            if (authWindow.closed) {
                window.clearInterval(pollTimer);
                // Retry creating the study plan after auth window is closed
                return true;
            }
        }, 500);
    } catch (error) {
        console.error('Unexpected error:', error);
        return false;
    }
}
