import React, { useState } from 'react';
import { Card } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

const PricingPlan = ({ data }) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const redirectPayment = () => {
        if (localStorage.getItem('auth') === null || localStorage.getItem('type') === 'free') {
            navigate('/payment', { state: { plan: data.type } });
        } else {
            navigate('/subscription');
        }
    };

    const renderFeatures = () => {
        if (Array.isArray(data.features)) {
            // Handle array of features (Free Plan)
            return (
                <ul className="space-y-2">
                    {data.features.map((feature, index) => (
                        <li key={index} className="flex space-x-3">
                            <svg
                                className="h-5 w-5 shrink-0 text-black dark:text-white"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="text-sm font-normal leading-tight text-black dark:text-white">
                                {feature}
                            </span>
                        </li>
                    ))}
                </ul>
            );
        } else if (typeof data.features === 'object') {
            // Handle object of feature categories (Monthly/Yearly Plan)
            return (
                <div className="space-y-4">
                    {Object.entries(data.features).map(([title, features], index) => (
                        <div key={index}>
                            <h6 className="font-bold text-md mb-2">{title}</h6>
                            <ul className="space-y-2">
                                {features.map((feature, featureIndex) => (
                                    <li key={featureIndex} className="flex space-x-3">
                                        <svg
                                            className="h-5 w-5 shrink-0 text-black dark:text-white"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <span className="text-sm font-normal leading-tight text-black dark:text-white">
                                            {feature}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            );
        } else {
            // Handle legacy format (one, two, three, four properties)
            return (
                <ul className="space-y-2">
                    {['one', 'two', 'three', 'four'].map((key, index) => (
                        data[key] && (
                            <li key={index} className="flex space-x-3">
                                <svg
                                    className="h-5 w-5 shrink-0 text-black dark:text-white"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="text-sm font-normal leading-tight text-black dark:text-white">
                                    {data[key]}
                                </span>
                            </li>
                        )
                    ))}
                </ul>
            );
        }
    };

    return (
        <Card className='w-full max-w-sm shadow-none border border-black rounded-none mt-3 px-6 py-5 dark:bg-black dark:border-white h-[800px] flex flex-col'>
            <h5 className="mb-4 text-2xl font-black text-black dark:text-white text-center">{data.type}</h5>
            <div className="flex items-baseline text-gray-900 dark:text-white text-center justify-center mb-4">
                <span className="text-2xl font-semibold">$</span>
                <span className="text-4xl font-extrabold tracking-tight">{data.cost}</span>
                <span className="ml-1 text-xl font-normal text-black dark:text-white">{data.time}</span>
            </div>
            <div className={``}>
                {renderFeatures()}
            </div>

        </Card>
    );
};

export default PricingPlan;