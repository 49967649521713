import React from 'react';

const AiRecommendationsModal = ({ recommendations, studyPlan, notifications, onClose }) => {
    return (
        <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full max-h-[90vh] overflow-y-auto">
                <h2 className="text-2xl font-bold mb-4">AI Recommendations</h2>

                {/* Study Strategy */}
                {recommendations.studyStrategy && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Study Strategy</h3>
                        <p className="text-gray-700 mb-2">{recommendations.studyStrategy.approach}</p>
                        <h4 className="font-semibold">Techniques:</h4>
                        <ul className="list-disc pl-5 space-y-2">
                            {recommendations.studyStrategy.techniques.map((technique, index) => (
                                <li key={index} className="text-gray-700">{technique}</li>
                            ))}
                        </ul>
                        <h4 className="font-semibold mt-2">Materials:</h4>
                        <ul className="list-disc pl-5 space-y-2">
                            {recommendations.studyStrategy.materials.map((material, index) => (
                                <li key={index} className="text-gray-700">{material}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* Time Management */}
                {recommendations.timeManagement && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Time Management</h3>
                        <h4 className="font-semibold">Breakdowns:</h4>
                        <ul className="list-disc pl-5 space-y-2">
                            {recommendations.timeManagement.breakdowns.map((breakdown, index) => (
                                <li key={index} className="text-gray-700">{breakdown}</li>
                            ))}
                        </ul>
                        <h4 className="font-semibold mt-2">Milestones:</h4>
                        <ul className="list-disc pl-5 space-y-2">
                            {recommendations.timeManagement.milestones.map((milestone, index) => (
                                <li key={index} className="text-gray-700">{milestone}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* Preparations */}
                {recommendations.preparations && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Preparations</h3>
                        <h4 className="font-semibold">Prerequisites:</h4>
                        <ul className="list-disc pl-5 space-y-2">
                            {recommendations.preparations.prerequisites.map((prerequisite, index) => (
                                <li key={index} className="text-gray-700">{prerequisite}</li>
                            ))}
                        </ul>
                        <h4 className="font-semibold mt-2">Resources:</h4>
                        <ul className="list-disc pl-5 space-y-2">
                            {recommendations.preparations.resources.map((resource, index) => (
                                <li key={index} className="text-gray-700">{resource}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* Motivational Quote */}
                {recommendations.motivationalQuote && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Motivational Quote</h3>
                        <blockquote className="italic text-gray-700 border-l-4 border-blue-500 pl-4">
                            "{recommendations.motivationalQuote}"
                        </blockquote>
                    </div>
                )}

                {/* Notifications */}
                {notifications && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Notifications</h3>
                        <p className="text-gray-700">Email: {notifications.email}</p>
                        <p className="text-gray-700">Quote: "{notifications.quote}"</p>
                    </div>
                )}

                {/* Study Plan */}
                {studyPlan && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Study Plan</h3>
                        <p className="text-gray-700">Subject: {studyPlan.subject}</p>
                        <p className="text-gray-700">Start: {studyPlan.start_date} {studyPlan.start_time}</p>
                        <p className="text-gray-700">End: {studyPlan.end_date} {studyPlan.end_time}</p>
                        <p className="text-gray-700">Difficulty: {studyPlan.difficulty}</p>
                        <p className="text-gray-700">Priority: {studyPlan.priority}</p>
                    </div>
                )}

                <div className="mt-6 flex justify-end">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                    >
                        Got it
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AiRecommendationsModal;
