import React from 'react';
import { Footer } from 'flowbite-react';
import Logo from '../res/img/logo.svg';
import DarkLogo from '../res/img/darkLogo.svg';
import { company, websiteURL } from '../constants';
import { useNavigate } from 'react-router-dom';

const Footers = () => {
  const storedTheme = localStorage.getItem('darkMode');

  const navigate = useNavigate();
  // function redirectAbout() {
  //   navigate("/about");
  // }
  function redirectContact() {
    navigate("/contact");
  }
  function redirectTerms() {
    navigate("/terms");
  }

  function redirectPrivacy() {
    navigate("/privacy");
  }
  function redirectPayment() {
    if (localStorage.getItem('auth') === null || localStorage.getItem('type') === 'free') {
      navigate('/payment');
    } else {
      navigate('/subscription');
    }
  }
  return (
    <Footer container className="shadow-none rounded-none dark:bg-black">
      <div className="w-full text-center">
        <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
          <Footer.Brand
            href={websiteURL}
            src={storedTheme === "true" ? DarkLogo : Logo}
          />
          <Footer.LinkGroup>


            {localStorage.getItem('type') !== 'free' && (
              <p className="text-black font-bold mx-4 dark:text-white" onClick={redirectPayment}>
                Cancel Subscription
              </p>
            )}
            {/* <p className="text-black font-bold mx-4 dark:text-white" onClick={redirectAbout}>
              About
            </p> */}
            <p className="text-black  font-bold  mx-4 dark:text-white" onClick={redirectPrivacy}>
              Privacy Policy
            </p>
            <p className="text-black  font-bold  mx-4 dark:text-white " onClick={redirectTerms}>
              Terms
            </p>
            <p className="text-black  font-bold  mx-4 dark:text-white" onClick={redirectContact}>
              Contact
            </p>
          </Footer.LinkGroup>
        </div>
        <Footer.Divider className="border-black dark:border-white" />
        <div>
          <p>&copy; 2024  <a href="https://learnooza.com/">Learnooza.com</a>. All rights reserved.</p>
        </div>


      </div>
    </Footer>
  );
};

export default Footers;
