import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';
import Header from '../components/header';
import Footers from '../components/footers';
import UserCourses from '../components/usercourses';
import { Layers, BookOpen, FileQuestion } from 'lucide-react';
import UserQuizzes from '../components/UserQuizzes';

const AccordionItem = ({ title, children, icon: Icon }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="mb-6">
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
                <button
                    className="w-full p-4 text-left bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-150 ease-in-out flex items-center"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="flex-shrink-0 mr-4 bg-blue-100 dark:bg-blue-900 p-2 rounded-full">
                        <Icon className="w-6 h-6 text-primary dark:text-white" />
                    </div>
                    <span className="flex-grow font-medium text-gray-800 dark:text-white">{title}</span>
                    <svg
                        className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </button>
            </div>
            {isOpen && (
                <div className="mt-2 w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
                    <div className="p-4 w-full">
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
};

const TasksTable = ({ tasks }) => {
    if (!Array.isArray(tasks) || tasks.length === 0) {
        return (
            <div className="bg-white mb-3 dark:bg-gray-800 shadow-lg rounded-lg p-4">
                <p className="text-lg text-gray-800 dark:text-white">No upcoming tasks found.</p>
            </div>
        );
    }

    return (
        <div className="overflow-x-auto mb-3 bg-white dark:bg-gray-800 shadow-lg rounded-lg">
         
            <table className="min-w-full table-auto">
                <thead>
                    <tr className="bg-gray-50 dark:bg-gray-700">
                        <th className="px-4 py-2 text-left text-gray-800 dark:text-white">Course / Quiz</th>
                        <th className="px-4 py-2 text-left text-gray-800 dark:text-white">Date</th>
                        <th className="px-4 py-2 text-left text-gray-800 dark:text-white">Start Time</th>
                        <th className="px-4 py-2 text-left text-gray-800 dark:text-white">End Time</th>
                        <th className="px-4 py-2 text-left text-gray-800 dark:text-white">Study Time</th>
                        <th className="px-4 py-2 text-left text-gray-800 dark:text-white">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map((task, index) => (
                        <tr
                            key={task._id || index}
                            className="border-t border-gray-200 dark:border-gray-600"
                        >
                            <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{task.subject || 'Course / Quiz Name'}</td>
                            <td className="px-4 py-2 text-gray-800  dark:text-gray-200">{task.start_date}</td>
                            <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{task.start_time}</td>
                            <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{task.end_time}</td>
                            <td className="px-4 py-2 text-gray-800 dark:text-gray-200">{task.studyTime}</td>
                            <td className="px-4 py-2">
                                <span className={`px-2 py-1 rounded-full text-sm ${task.status === 'completed'
                                    ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                    : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                                    }`}>
                                    {task.status || 'Pending'}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tasks, setTasks] = useState([]);
    const userId = localStorage.getItem('uid');

    useEffect(() => {
        const fetchUpcomingTasks = async () => {
            try {
                setIsLoading(true);
                setError(null);
                const response = await axios.get(`${serverURL}/api/getUpcomingTasks?userId=${userId}`);

                // Handle both array and message responses
                if (response.data.data) {
                    setTasks(response.data.data);
                } else if (Array.isArray(response.data)) {
                    setTasks(response.data);
                } else {
                    setTasks([]);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
                setError('Failed to fetch upcoming tasks. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        if (userId) {
            fetchUpcomingTasks();
        }
    }, [userId]);

    return (
        <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className="flex-1 p-4">
                <h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Dashboard</h1>

                {isLoading ? (
                    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className="text-gray-800 dark:text-white">Loading tasks...</p>
                    </div>
                ) : error ? (
                    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className="text-red-600 dark:text-red-400">{error}</p>
                    </div>
                ) : (
                    <TasksTable tasks={tasks} />
                )}

                <AccordionItem title="Courses" icon={BookOpen}>
                    <UserCourses userId={userId} />
                </AccordionItem>

                <AccordionItem title="Quiz" icon={FileQuestion}>
                    <div className="text-gray-600 dark:text-gray-300">
                        <UserQuizzes />
                    </div>
                </AccordionItem>
            </div>
            <Footers className="mt-auto" />
        </div>
    );
};

export default Home;