import React, { useState } from 'react';
import { FileText, Upload, X } from 'lucide-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { serverURL } from '../../constants';
const showToast = async (msg) => {
    toast(msg, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
}
const FileUploadSection = ({ onTextExtracted, onRemove }) => {
    const [extractedText, setExtractedText] = useState('');
    const [fileName, setFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const uploadAndExtractText = async (file) => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(
                `${serverURL}/api/extract-text`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            const extractedText = response.data.text;
            setExtractedText(extractedText);
            onTextExtracted(extractedText);
        } catch (error) {
            console.error('Error uploading file:', error);
            showToast('Error extracting text from the file');
        }
        setIsLoading(false);
    };


    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file.size > 10 * 1024 * 1024) { // 10MB limit
            showToast('File size should be less than 10MB');
            return;
        }
        setFileName(file.name);
        await uploadAndExtractText(file);
    };

    const handleRemove = () => {
        setFileName('');
        setExtractedText('');
        onRemove();
    };

    return (
        <div className="mt-2">
            <div className="mt-1 flex items-center justify-between">
                <span className="text-sm text-gray-500">Upload related PDF (optional)</span>
                {fileName && (
                    <button
                        onClick={handleRemove}
                        className="text-sm text-red-500 hover:text-red-700"
                    >
                        Remove
                    </button>
                )}
            </div>

            {!fileName ? (
                <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <Upload className="w-8 h-8 mb-2 text-gray-500" />
                            <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">Click to upload PDF</span>
                            </p>
                            <p className="text-xs text-gray-500">PDF (max 10MB)</p>
                        </div>
                        <input
                            type="file"
                            className="hidden"
                            accept=".txt,.csv,.log,.json,.pdf,.xml,.html,.md,.ini,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

                            onChange={handleFileChange}
                        />
                    </label>
                </div>
            ) : (
                <div className="flex items-center gap-2 p-2 border rounded-lg bg-gray-50">
                    <FileText className="w-5 h-5 text-gray-500" />
                    <span className="flex-1 text-sm truncate">{fileName}</span>
                    {isLoading ? (
                        <div className="w-5 h-5 border-t-2 border-blue-500 rounded-full animate-spin" />
                    ) : (
                        <button
                            onClick={handleRemove}
                            className="p-1 hover:bg-gray-200 rounded-full"
                        >
                            <X className="w-4 h-4 text-gray-500" />
                        </button>
                    )}
                </div>
            )}
            <textarea
                className="hidden"
                value={extractedText}
                readOnly
            />
        </div>
    );
};

export default FileUploadSection;