import React, { useState, useEffect } from 'react';

const TaskModal = ({ event, onClose, onUpdate, onDelete }) => {
    const [formData, setFormData] = useState({
        task: '',
        status: 'pending',
        progress: 0,
        difficulty: 'medium',
        notes: '',
        unit: 'minute',
        duration: '30',
        subject: '',
        id: "",
        datetime: "",
        aiChanges: ""
    });

    useEffect(() => {
        if (event) {
            console.log(event.extendedProps.start_date + "T" + event.extendedProps.start_time);

            const startDate = new Date(event.extendedProps.start_date + "T" + event.extendedProps.start_time);
            const endDate = new Date(event.extendedProps.end_date + "T" + event.extendedProps.end_time);

            const { differenceValue, unit } = calculateDateDifference(startDate, endDate);
            console.log(event.extendedProps);

            setFormData({
                id: event?.id || '',
                task: event.extendedProps?.task || '',
                datetime: event.extendedProps.start_date + "T" + event.extendedProps.start_time,
                subject: event.extendedProps?.subject || '',
                status: event.extendedProps?.status || 'pending',
                progress: event.extendedProps?.progress || 0,
                difficulty: event.extendedProps?.difficulty || 'medium',
                notes: event.extendedProps?.notes || '',
                aiChanges: event.extendedProps?.aiChanges || '',
                duration: differenceValue.toString(),
                unit: unit,
            });
            console.log(event);


            // If you want to debug the updated state, use a separate `useEffect` for formData
        }
    }, [event]);

    function calculateDateDifference(startDate, endDate) {
        // Calculate the difference in milliseconds
        const diffInMs = endDate - startDate;

        // Convert the difference into days, hours, and minutes
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24); // Days
        const diffInHours = diffInMs / (1000 * 60 * 60); // Hours
        const diffInMinutes = diffInMs / (1000 * 60); // Minutes

        // Determine the most suitable unit based on the difference
        let differenceValue;
        let unit;

        if (diffInDays >= 1) {
            differenceValue = Math.floor(diffInDays);
            unit = "day";
        } else if (diffInHours >= 1) {
            differenceValue = Math.floor(diffInHours);
            unit = "hour";
        } else if (diffInMinutes >= 1) {
            differenceValue = Math.floor(diffInMinutes);
            unit = "minute";
        } else {
            differenceValue = 0;
            unit = "minute"; // Default to minute if very small
        }

        return { differenceValue, unit };
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onUpdate(event?.id || '', formData);
        onClose();
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this task?')) {
            onDelete(event.id);
        }
    };

    return (
        <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full max-h-[90vh] overflow-y-auto">
                <h2 className="text-2xl font-bold mb-4">
                    {event ? 'Update Task' : 'Add New Task'}
                </h2>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Select Start Time
                        </label>
                        <input
                            type="datetime-local"
                            value={formData.datetime}
                            onChange={(e) => setFormData(prev => ({ ...prev, datetime: e.target.value }))}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                        <input
                            type="hidden"
                            value={formData.id}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"

                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Select Duration
                        </label>
                        <div className="flex space-x-2">
                            <input
                                type="number"
                                min="1"
                                value={formData.duration}
                                onChange={(e) => setFormData(prev => ({ ...prev, duration: e.target.value }))}
                                className="w-1/2 border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            />
                            <select
                                value={formData.unit}
                                onChange={(e) => setFormData(prev => ({ ...prev, unit: e.target.value }))}
                                className="w-1/2 border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                            >
                                <option value="minute">Minute</option>
                                <option value="hour">Hour</option>
                                <option value="day">Day</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Subject
                        </label>
                        <input
                            type="text"
                            value={formData.subject}
                            onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Task Description
                        </label>
                        <input
                            type="text"
                            value={formData.task}
                            onChange={(e) => setFormData(prev => ({ ...prev, task: e.target.value }))}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"

                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Status
                        </label>
                        <select
                            value={formData.status}
                            onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="pending">Pending</option>
                            <option value="in-progress">In Progress</option>
                            <option value="completed">Completed</option>
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>

                    {/* <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Progress ({formData.progress}%)
                        </label>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={formData.progress}
                            onChange={(e) => setFormData(prev => ({ ...prev, progress: parseInt(e.target.value) }))}
                            className="w-full"
                        />
                    </div> */}

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Difficulty
                        </label>
                        <select
                            value={formData.difficulty}
                            onChange={(e) => setFormData(prev => ({ ...prev, difficulty: e.target.value }))}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="easy">Easy</option>
                            <option value="medium">Medium</option>
                            <option value="hard">Hard</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Notes
                        </label>
                        <textarea
                            value={formData.notes}
                            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows="3"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Ai update
                        </label>
                        <textarea
                            value={formData.aiChanges}
                            disabled
                            readOnly
                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows="3"
                        />
                    </div>

                    <div className="flex justify-end space-x-3 pt-4">
                        {event && (
                            <button
                                type="button"
                                onClick={handleDelete}
                                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                            >
                                Delete
                            </button>
                        )}
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                        >
                            {event ? 'Update' : 'Add'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default TaskModal;