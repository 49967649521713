import { Navbar, Sidebar } from 'flowbite-react';
import React, { useEffect, useState } from 'react';

import LogoComponent from '../components/LogoComponent';
import { FiMenu, FiX } from 'react-icons/fi';
import DarkModeToggle from '../components/DarkModeToggle';
import TruncatedText from '../components/TruncatedText';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import StyledText from '../components/styledText';
import YouTube from 'react-youtube';
import { toast } from 'react-toastify';
import { logo, name, serverURL } from '../constants';
import axios from 'axios';
import { FaCheck } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Select from 'react-select';
import QuestionModal from './questionModal';
import AiModal from './AiModal';
const showToast = async (msg) => {
    toast(msg, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });
}
const Course = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [key, setkey] = useState('');
    const { state } = useLocation();
    const { mainTopic, type, courseId, end } = state || {};
    const jsonData = JSON.parse(localStorage.getItem('jsonData'));
    const storedTheme = localStorage.getItem('darkMode');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selected, setSelected] = useState('');
    const [theory, setTheory] = useState('');
    const [media, setMedia] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isComplete, setIsCompleted] = useState(false);
    const navigate = useNavigate();
    const [userDropdown, setUserDropdown] = useState("hidden");

    const [users, setUsers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [downloadText, setDownloadText] = useState("Download Course");
    const courseTopic = mainTopic.toLowerCase();
    localStorage.setItem("courseTopic", [courseTopic]);
    useEffect(() => {


        async function usersData() {

            const postURL = serverURL + `/api/getusers`;
            return await axios.get(postURL);

        }
        usersData().then((response) => {
            let usersObj = JSON.parse(response.request.response);

            setUsers(JSON.parse(response.request.response));

            const userArray = usersObj.map(user => ({
                value: user._id,
                label: user.mName
            }));
            setUserData(userArray);
        });

    }, []);
    useEffect(() => {
        let courseSubtopics = [];
        jsonData[mainTopic.toLowerCase()].forEach((topic) => {
            topic.subtopics.forEach((subtopic) => {
                courseSubtopics.push(subtopic.title);
            });
        });
        // Convert the array to a comma-separated string
        const courseSubtopicsString = courseSubtopics.join(',');
        // Store the comma-separated string in localStorage
        localStorage.setItem("courseSubtopics", courseSubtopicsString);
    }, []);



    const submitUserDropDown = () => {
        selectedValue.forEach(userId => {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow"
            };
            fetch(serverURL + "/api/shareCourse/" + courseId + "/" + userId, requestOptions)
                .then((response) => response.text(), showToast("Successfully shared!"))
                .then((result) => console.log(result))
                .catch((error) => console.error(error));
        });

    }


    const handleChange = (e) => {

        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);

    }


    const CountDoneTopics = () => {
        let doneCount = 0;
        let totalTopics = 0;

        jsonData[mainTopic.toLowerCase()].forEach((topic) => {

            topic.subtopics.forEach((subtopic) => {

                if (subtopic.done) {
                    doneCount++;
                }
                totalTopics++;
            });
        });
        const completionPercentage = Math.round((doneCount / totalTopics) * 100);
        setPercentage(completionPercentage);
        if (completionPercentage >= '100') {
            setIsCompleted(true);
        }
    }

    const opts = {
        height: '390',
        width: '640',
    };

    const optsMobile = {
        height: '250px',
        width: '100%',
    };

    async function finish() {
        if (localStorage.getItem('first') === 'true') {
            if (!end) {
                const today = new Date();
                const formattedDate = today.toLocaleDateString('en-GB');
                navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
            } else {
                navigate('/certificate', { state: { courseTitle: mainTopic, end: end } });
            }

        } else {
            const dataToSend = {
                courseId: courseId
            };
            try {
                const postURL = serverURL + '/api/finish';
                const response = await axios.post(postURL, dataToSend);
                if (response.data.success) {
                    const today = new Date();
                    const formattedDate = today.toLocaleDateString('en-GB');
                    localStorage.setItem('first', 'true');
                    sendEmail(formattedDate);
                } else {
                    finish()
                }
            } catch (error) {
                finish()
            }
        }
    }

    async function sendEmail(formattedDate) {
        const userName = localStorage.getItem('mName');
        const email = localStorage.getItem('email');
        const html = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="initial-scale=1.0">
            <title>Certificate of Completion</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap">
            <style>
            body {
                font-family: 'Roboto', sans-serif;
                text-align: center;
                background-color: #fff;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
            }
        
            .certificate {
                border: 10px solid #000;
                max-width: 600px;
                margin: 20px auto;
                padding: 50px;
                background-color: #fff;
                position: relative;
                color: #000;
                text-align: center;
            }
        
            h1 {
                font-weight: 900;
                font-size: 24px;
                margin-bottom: 10px;
            }
        
            h4 {
                font-weight: 900;
                text-align: center;
                font-size: 20px;
            }
        
            h2 {
                font-weight: 700;
                font-size: 18px;
                margin-top: 10px;
                margin-bottom: 5px;
                text-decoration: underline;
            }
        
            h3 {
                font-weight: 700;
                text-decoration: underline;
                font-size: 16px;
                margin-top: 5px;
                margin-bottom: 10px;
            }
        
            p {
                font-weight: 400;
                line-height: 1.5;
            }
        
            img {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                text-align: center;
                align-self: center;
            }
            </style>
        </head>
        <body>
        
        <div class="certificate">
        <h1>Certificate of Completion 🥇</h1>
        <p>This is to certify that</p>
        <h2>${userName}</h2>
        <p>has successfully completed the course on</p>
        <h3>${mainTopic}</h3>
        <p>on ${formattedDate}.</p>
    
        <div class="signature">
            <img src=${logo}>
            <h4>${name}</h4>
        </div>
    </div>
        
        </body>
        </html>`;

        try {
            const postURL = serverURL + '/api/sendcertificate';
            await axios.post(postURL, { html, email }).then(res => {
                navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
            }).catch(error => {
                navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
            });

        } catch (error) {
            navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
        }

    }

    useEffect(() => {

        const CountDoneTopics = () => {
            let doneCount = 0;
            let totalTopics = 0;

            jsonData[mainTopic.toLowerCase()].forEach((topic) => {

                topic.subtopics.forEach((subtopic) => {

                    if (subtopic.done) {
                        doneCount++;
                    }
                    totalTopics++;
                });
            });
            const completionPercentage = Math.round((doneCount / totalTopics) * 100);
            setPercentage(completionPercentage);
            if (completionPercentage >= '100') {
                setIsCompleted(true);
            }
        }

        if (!mainTopic) {
            navigate("/create");
        } else {
            if (percentage >= '100') {
                setIsCompleted(true);
            }

            const mainTopicData = jsonData[mainTopic.toLowerCase()][0];
            const firstSubtopic = mainTopicData.subtopics[0];
            firstSubtopic.done = true
            setSelected(firstSubtopic.title)
            setTheory(firstSubtopic.theory)
            localStorage.setItem("courseSubtopic", firstSubtopic.title);
            console.log(localStorage.getItem("courseSubtopic"));

            if (type === 'video & text course') {
                setMedia(firstSubtopic.youtube);
            } else {
                setMedia(firstSubtopic.image)

            }
            localStorage.setItem('jsonData', JSON.stringify(jsonData));
            CountDoneTopics();

        }

    }, []);

    const handleSelect = (topics, sub) => {

        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);

        if (mSubTopic.theory === '' || mSubTopic.theory === undefined || mSubTopic.theory === null) {
            if (type === 'video & text course') {

                const query = `${mSubTopic.title} ${mainTopic} in english`;
                const id = toast.loading("Please wait...")
                sendVideo(query, topics, sub, id, mSubTopic.title);

            } else {

                const prompt = `Explain me about this subtopic of ${mainTopic} with examples :- ${mSubTopic.title}. Please Strictly Don't Give Additional Resources And Images and use <sup> element for numbers   , Don't use <code> element for parent in case of calculation.`;
                const promptImage = `Example of ${mSubTopic.title} in ${mainTopic}`;
                const id = toast.loading("Please wait...")
                sendPrompt(prompt, promptImage, topics, sub, id);

            }
        } else {
            setSelected(mSubTopic.title)

            setTheory(mSubTopic.theory)
            localStorage.setItem("courseSubtopic", mSubTopic.title);

            if (type === 'video & text course') {
                console.log(mSubTopic.theory)
                setMedia(mSubTopic.youtube);
            } else {
                console.log(mSubTopic.theory)
                setMedia(mSubTopic.image)
            }
        }

    };

    async function sendPrompt(prompt, promptImage, topics, sub, id, sendData = true) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;
            try {
                const parsedJson = htmlContent;
                if (!sendData) {
                    buildCourseHtml(parsedJson, topics, sub);
                    return;
                }
                await sendImage(parsedJson, promptImage, topics, sub, id, sendData);
            } catch (error) {
                // sendPrompt(prompt, promptImage, topics, sub, id,sendData)
            }

        } catch (error) {
            // sendPrompt(prompt, promptImage, topics, sub, id,sendData)
        }
    }

    async function sendImage(parsedJson, promptImage, topics, sub, id, sendData = true) {
        const dataToSend = {
            prompt: promptImage,
        };
        try {
            const postURL = serverURL + '/api/image';
            const res = await axios.post(postURL, dataToSend);
            try {
                const generatedText = res.data.url;
                if (!sendData) {
                    buildCourseHtml(parsedJson, generatedText, topics, sub)
                    return;
                }
                sendData(generatedText, parsedJson, topics, sub, id, sendData);
            } catch (error) {
                await sendImage(parsedJson, promptImage, topics, sub, id, sendData)
            }

        } catch (error) {
            await sendImage(parsedJson, promptImage, topics, sub, id, sendData)
        }
    }

    async function sendData(image, theory, topics, sub, id) {

        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);
        mSubTopic.theory = theory
        mSubTopic.image = image;
        setSelected(mSubTopic.title)

        toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        setTheory(theory)
        if (type === 'video & text course') {
            setMedia(mSubTopic.youtube);
        } else {
            setMedia(image)
        }
        mSubTopic.done = true;
        updateCourse();
    }

    async function sendDataVideo(image, theory, topics, sub, id) {

        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);
        mSubTopic.theory = theory
        mSubTopic.youtube = image;
        setSelected(mSubTopic.title)

        toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        setTheory(theory)
        if (type === 'video & text course') {
            setMedia(image);
        } else {
            setMedia(mSubTopic.image)
        }
        localStorage.setItem('courseSubtopic', mSubTopic.title);

        mSubTopic.done = true;
        updateCourse();

    }

    async function updateCourse() {
        CountDoneTopics();
        localStorage.setItem('jsonData', JSON.stringify(jsonData));
        const dataToSend = {
            content: JSON.stringify(jsonData),
            courseId: courseId
        };
        try {
            const postURL = serverURL + '/api/update';
            await axios.post(postURL, dataToSend);
        } catch (error) {
            updateCourse()
        }
    }

    async function sendVideo(query, mTopic, mSubTopic, id, subtop, updateCourse = true) {
        const dataToSend = {
            prompt: query,
        };
        try {
            const postURL = serverURL + '/api/yt';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                await sendTranscript(generatedText, mTopic, mSubTopic, id, subtop, updateCourse);
            } catch (error) {
                sendVideo(query, mTopic, mSubTopic, id, subtop, updateCourse)
            }

        } catch (error) {
            sendVideo(query, mTopic, mSubTopic, id, subtop, updateCourse)
        }
    }

    async function sendTranscript(url, mTopic, mSubTopic, id, subtop, updateCourse) {
        const dataToSend = {
            prompt: url,
        };
        try {
            const postURL = serverURL + '/api/transcript';
            const res = await axios.post(postURL, dataToSend);

            try {
                const generatedText = res.data.url;
                const allText = generatedText.map(item => item.text);
                const concatenatedText = allText.join(' ');
                const prompt = `Summarize this theory in a teaching way, use proper html elements for calculation and numbers part and use <sup> element for numbers , Don't use <code> element for parent in case of calculation. Please Use h3 tag for subtopic title:- ${concatenatedText}.`;
                await sendSummery(prompt, url, mTopic, mSubTopic, id, updateCourse);
            } catch (error) {
                const prompt = `Explain me about this subtopic of ${mainTopic} with examples :- ${subtop}. Please Strictly Don't Give Additional Resources And Images and use <sup> element for numbers  , Don't use <code> element for parent in case of calculation. Use h3 tag for subtopic title`;
                await sendSummery(prompt, url, mTopic, mSubTopic, id, updateCourse);
            }

        } catch (error) {
            const prompt = `Explain me about this subtopic of ${mainTopic} with examples :- ${subtop}.  Please Strictly Don't Give Additional Resources And Images and use <sup> element for numbers  , Don't use <code> element for parent in case of calculation.Use h3 tag for subtopic title`;
            await sendSummery(prompt, url, mTopic, mSubTopic, id, updateCourse);
        }
    }

    async function sendSummery(prompt, url, mTopic, mSubTopic, id, updateCourse) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;
            console.log(htmlContent)
            try {
                const parsedJson = htmlContent;
                if (!updateCourse) {
                    buildVideoCourseHtml(parsedJson, url, mTopic, mSubTopic)
                    return;
                }
                sendDataVideo(url, parsedJson, mTopic, mSubTopic, id);
            } catch (error) {
                sendSummery(prompt, url, mTopic, mSubTopic, id)
            }

        } catch (error) {
            sendSummery(prompt, url, mTopic, mSubTopic, id)
        }
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleOpenClose = (keys) => {
        setIsOpen(!isOpen)
        setkey(keys);
    };
    let htmlText = "";
    const downloadImageCourse = () => {
        setDownloadText("Downloading...");
        let courseData = jsonData[mainTopic.toLowerCase()]
        async function processCourseData(courseData) {
            for (const [topicIndex, courseMainTopic] of courseData.entries()) {
                const promises = [];
                for (const [subtopicIndex, courseSubtopic] of courseMainTopic.subtopics.entries()) {
                    // if(courseSubtopic.theory!==""){
                    //     continue;
                    // }
                    const prompt = `Explain me about this subtopic of ${mainTopic} with examples :- ${courseSubtopic.title}. Please Strictly Don't Give Additional Resources And Images and use <sup> element for numbers  , Don't use <code> element for parent in case of calculation. Please use h3 element for title`;
                    const promptImage = `Example of ${courseSubtopic.title} in ${mainTopic}`;
                    promises.push(sendPrompt(prompt, promptImage, topicIndex, subtopicIndex, 1, false));
                }
                // Wait for all sendPrompt promises to resolve before continuing to the next main topic
                await Promise.all(promises);
            }
            console.log('All prompts sent');
        }

        // Call the function with your courseData
        processCourseData(courseData).then(() => generateHtml());


    }
    const downloadVideoCourse = () => {
        setDownloadText("Downloading...");

        let courseData = jsonData[mainTopic.toLowerCase()]
        async function processCourseData(courseData) {
            for (const [topicIndex, courseMainTopic] of courseData.entries()) {
                const promises = [];
                for (const [subtopicIndex, courseSubtopic] of courseMainTopic.subtopics.entries()) {

                    const query = `${courseSubtopic.title} ${mainTopic} in english`;
                    promises.push(sendVideo(query, topicIndex, subtopicIndex, 1, courseSubtopic.title, false));
                }
                await Promise.all(promises);
            }
            console.log('All prompts sent');
        }

        processCourseData(courseData).then(() => generateVideoHtml());


    }
    const generateHtml = () => {
        let courseData = jsonData[mainTopic.toLowerCase()]
        let htmlText = "";
        htmlText += `<h1>${mainTopic}</h1>`

        courseData.forEach((courseMainTopic, topicIndex) => {
            courseMainTopic.subtopics.forEach((courseSubtopic, subtopicIndex) => {
                htmlText += courseSubtopic.theory;
                htmlText += `<div class="page_break"></div>`
            });
        });
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/html");
        setDownloadText("Downloaded");
        const requestOptions = {
            method: "POST",
            headers: {
                ...myHeaders,
                "Accept": "application/json" // Specify that you prefer JSON response
            },
            body: htmlText,
            redirect: "follow"
        };

        fetch("https://weblearningai.com/course/download", requestOptions)
            .then((response) => response.text())
            .then((result) => window.location.href = "https://weblearningai.com/course/get/" + result.replace(/"/g, ""))
            .catch((error) => console.error(error));
    }
    const generateVideoHtml = () => {

        let courseData = jsonData[mainTopic.toLowerCase()]
        let htmlText = "";
        htmlText += `<h1>${mainTopic}</h1>`

        courseData.forEach((courseMainTopic, topicIndex) => {
            courseMainTopic.subtopics.forEach((courseSubtopic, subtopicIndex) => {
                htmlText += courseSubtopic.theory;
                htmlText += `<a href="https://www.youtube.com/watch?v=${courseSubtopic.youtube}" >Watch Video</a><div class="page_break"></div>`
            });
        });
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/html");
        console.log('completed');

        setDownloadText("Downloaded");


        const requestOptions = {
            method: "POST",
            headers: {
                ...myHeaders,
                "Accept": "application/json" // Specify that you prefer JSON response
            },
            body: htmlText,
            redirect: "follow"
        };

        fetch("https://weblearningai.com/course/download", requestOptions)
            .then((response) => response.text())
            .then((result) => window.location.href = "https://weblearningai.com/course/get/" + result.replace(/"/g, ""))
            .catch((error) => console.error(error));
    }
    const buildCourseHtml = (theory, topic, subTopicIndex) => {
        let courseData = jsonData[mainTopic.toLowerCase()]
        courseData[topic].subtopics[subTopicIndex].theory = theory;
        htmlText += theory;
    }
    const buildVideoCourseHtml = (theory, youtube, topic, subTopicIndex) => {
        let courseData = jsonData[mainTopic.toLowerCase()]
        courseData[topic].subtopics[subTopicIndex].theory = theory;
        courseData[topic].subtopics[subTopicIndex].youtube = youtube;
    }
    async function handleDeleteCourse() {
        try {
            const postURL = serverURL + '/api/course/delete/' + courseId;
            const response = await axios.post(postURL);
            if (response.status === 200) {
                // Redirect user to the home page using a history object (assuming React Router)
                navigate('/home');
            } else {
                // Handle non-200 status codes gracefully
                console.error('Unexpected response status:', response.status);
                // Optionally display an error message to the user
            }

        } catch (error) {

        }
    }
    const style = {
        "root": {
            "base": "h-full",
            "collapsed": {
                "on": "w-16",
                "off": "w-64"
            },
            "inner": "no-scrollbar h-full overflow-y-auto overflow-x-hidden rounded-none border-black dark:border-white md:border-r  bg-white py-4 px-3 dark:bg-black"
        }
    }

    const renderTopicsAndSubtopics = (topics) => {

        try {
            return (
                <div>
                    {topics.map((topic) => (
                        <Sidebar.ItemGroup key={topic.title}>
                            <div className="relative inline-block text-left " >
                                <button
                                    onClick={() => handleOpenClose(topic.title)}
                                    type="button"
                                    className="inline-flex text-start text-base w-64 font-bold  text-black dark:text-white"
                                >
                                    {topic.title}
                                    <IoIosArrowDown className="-mr-1 ml-2 h-3 w-3 mt-2" />
                                </button>

                                {isOpen && key === topic.title && (
                                    <div className="origin-top-right mt-2 pr-4">
                                        <div
                                            className="py-1"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            {topic.subtopics.map((subtopic) => (
                                                <p
                                                    key={subtopic.title}

                                                    onClick={() => handleSelect(topic.title, subtopic.title)}
                                                    className="flex py-2 text-sm flex-row items-center font-normal text-black dark:text-white  text-start"
                                                    role="menuitem"
                                                >
                                                    {subtopic.title}
                                                    {subtopic.done === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                                </p>

                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Sidebar.ItemGroup>
                    ))}
                </div>
            );
        } catch (error) {
            return (
                <div>
                    {topics.map((topic) => (
                        <Sidebar.ItemGroup key={topic.Title}>
                            <div className="relative inline-block text-left " >
                                <button
                                    onClick={() => handleOpenClose(topic.Title)}
                                    type="button"
                                    className="inline-flex text-start text-base w-64 font-bold  text-black dark:text-white"
                                >
                                    {topic.Title}
                                    <IoIosArrowDown className="-mr-1 ml-2 h-3 w-3 mt-2" />
                                </button>

                                {isOpen && key === topic.Title && (
                                    <div className="origin-top-right mt-2 pr-4">
                                        <div
                                            className="py-1"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            {topic.Subtopics.map((subtopic) => (
                                                <p
                                                    key={subtopic.Title}
                                                    onClick={() => handleSelect(topic.Title, subtopic.Title)}
                                                    className="flex py-2 flex-row text-sm items-center font-normal text-black dark:text-white  text-start"
                                                    role="menuitem"
                                                >
                                                    {subtopic.Title}
                                                    {subtopic.done === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Sidebar.ItemGroup>
                    ))}
                </div>
            );
        }
    };

    return (
        <>
            {!mainTopic ? <></>
                :
                <div>
                    <div className="flex bg-white dark:bg-black md:hidden pb-10 overflow-y-auto">
                        <div className={`fixed inset-0 bg-black opacity-50 z-50 ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
                        <div className="flex-1 flex flex-col overflow-hidden">

                            <div>
                                <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:border-white md:border-b'>
                                    <Navbar.Brand className='ml-1'>

                                        {isComplete ?
                                            <p onClick={finish} className='mr-3 underline text-black dark:text-white font-normal'>Certificate</p>
                                            :
                                            <div className='w-7 h-7 mr-3'>
                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        rotation: 0.25,
                                                        strokeLinecap: 'butt',
                                                        textSize: '20px',
                                                        pathTransitionDuration: 0.5,
                                                        pathColor: storedTheme === "true" ? '#fff' : '#000',
                                                        textColor: storedTheme === "true" ? '#fff' : '#000',
                                                        trailColor: storedTheme === "true" ? 'grey' : '#d6d6d6',
                                                    })}
                                                />
                                            </div>
                                        }

                                        <TruncatedText text={mainTopic} len={6} />
                                    </Navbar.Brand>
                                    <div className='flex md:hidden justify-center items-center'>
                                        <DarkModeToggle className='inline-flex items-center md:hidden' />
                                        {isSidebarOpen ? (
                                            <FiX
                                                onClick={toggleSidebar}
                                                className='mx-2'
                                                size={20}
                                                color={localStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                            />
                                        ) : (
                                            <FiMenu
                                                onClick={toggleSidebar}
                                                className='mx-2'
                                                size={20}
                                                color={localStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                            />
                                        )}
                                    </div>
                                    <Navbar.Collapse>
                                        <div className='hidden md:flex justify-center items-center mb-2 mt-2'>
                                            <DarkModeToggle />
                                        </div>
                                    </Navbar.Collapse>
                                </Navbar>

                            </div>

                            <Sidebar
                                aria-label="Default sidebar example"
                                theme={style}
                                className={`md:border-r md:border-black md:dark:border-white dark:bg-black fixed inset-y-0 left-0 w-64  bg-white z-50 overflow-y-auto transition-transform transform lg:translate-x-0 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
                            >
                                <LogoComponent isDarkMode={storedTheme} />
                                <Sidebar.Items className='mt-6'>

                                    {jsonData && renderTopicsAndSubtopics(jsonData[mainTopic.toLowerCase()])}

                                </Sidebar.Items>
                            </Sidebar>
                            <div className='mx-5 overflow-y-auto bg-white dark:bg-black'>
                                <p className='font-black text-black dark:text-white text-lg'>{selected}</p>

                                <div className=' mt-5 text-black dark:text-white text-base pb-10 max-w-full'>
                                    {type === 'video & text course' ?
                                        <div>
                                            <YouTube key={media} className='mb-5' videoId={media} opts={optsMobile} />
                                            <StyledText text={theory} />
                                            <div className="flex justify-center items-center mt-3">

                                                <button
                                                    onClick={(event) => {
                                                        event.target.disabled = true;
                                                        downloadVideoCourse();
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black">{downloadText} </button>
                                                <button
                                                    onClick={(event) => {
                                                        setUserDropdown("visible")
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4">Share Course</button>
                                                <QuestionModal courseTopic={[courseTopic]}  ></QuestionModal>
                                                <button
                                                    className="mx-2 delete-course-btn bg-red-500 hover:bg-red-700 text-white px-3 py-2 rounded font-medium focus:outline-none"
                                                    onClick={() => handleDeleteCourse()} // Replace with your deletion logic
                                                >
                                                    Delete Course
                                                </button>
                                                <AiModal courseTopic={[courseTopic]} />
                                                <div className=""></div>
                                            </div>

                                            <Select
                                                className={`${userDropdown} dropdown mt-2`}
                                                placeholder="Select Option"
                                                value={userData.filter(obj => selectedValue.includes(obj.value))} // set selected values
                                                options={userData} // set list of the data
                                                onChange={handleChange} // assign onChange function
                                                isMulti
                                                isClearable
                                            />
                                            <button
                                                onClick={(event) => {
                                                    submitUserDropDown()
                                                }}
                                                className={`${userDropdown} bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4 mt-2`}>Submit</button>
                                            <div className=""></div>
                                        </div>

                                        :
                                        <div>
                                            <StyledText text={theory} />
                                            <img className='overflow-hidden p-10' src={media} alt="Media" />
                                            <div className="flex justify-center items-center">

                                                <button
                                                    onClick={(event) => {
                                                        event.target.disabled = true;
                                                        downloadImageCourse();
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black">{downloadText} </button>
                                                <button
                                                    onClick={(event) => {
                                                        setUserDropdown("visible")
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4">Share Course</button>
                                                <div className=""></div>
                                                <QuestionModal courseTopic={courseTopic} ></QuestionModal>

                                                <button
                                                    className="mx-2 delete-course-btn bg-red-500 hover:bg-red-700 text-white px-3 py-2 rounded font-medium focus:outline-none"
                                                    onClick={() => handleDeleteCourse()} // Replace with your deletion logic
                                                >
                                                    Delete Course
                                                </button>
                                                <AiModal courseTopic={courseTopic} />
                                            </div>
                                            <Select
                                                className={`${userDropdown} dropdown mt-2`}
                                                placeholder="Select Option"
                                                value={userData.filter(obj => selectedValue.includes(obj.value))} // set selected values
                                                options={userData} // set list of the data
                                                onChange={handleChange} // assign onChange function
                                                isMulti
                                                isClearable
                                            />
                                            <button
                                                onClick={(event) => {
                                                    submitUserDropDown()
                                                }}
                                                className={`${userDropdown} bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4 mt-2`}>Submit</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row overflow-y-auto h-screen max-md:hidden'>
                        <Sidebar
                            theme={style}
                            aria-label="Default sidebar example">
                            <LogoComponent isDarkMode={storedTheme} />
                            <Sidebar.Items className='mt-6'>

                                {jsonData && renderTopicsAndSubtopics(jsonData[mainTopic.toLowerCase()])}

                            </Sidebar.Items>
                        </Sidebar>
                        <div className='overflow-y-auto flex-grow flex-col'>
                            <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:border-white md:border-b'>
                                <Navbar.Brand className='ml-1'>
                                    {isComplete ?
                                        <p onClick={finish} className='mr-3 underline text-black dark:text-white font-normal'>{downloadText} Certificate</p> :
                                        <div className='w-8 h-8 mr-3'>
                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    rotation: 0.25,
                                                    strokeLinecap: 'butt',
                                                    textSize: '20px',
                                                    pathTransitionDuration: 0.5,
                                                    pathColor: storedTheme === "true" ? '#fff' : '#000',
                                                    textColor: storedTheme === "true" ? '#fff' : '#000',
                                                    trailColor: storedTheme === "true" ? 'grey' : '#d6d6d6',
                                                })}
                                            />
                                        </div>
                                    }
                                    <TruncatedText text={mainTopic} len={10} />
                                </Navbar.Brand>
                                <Navbar.Collapse>
                                    <div className='hidden md:flex justify-center items-center mb-2 mt-2'>

                                        <DarkModeToggle />
                                    </div>
                                </Navbar.Collapse>
                            </Navbar>
                            <div className='px-5 bg-white dark:bg-black pt-5'>
                                <p className='font-black text-black dark:text-white text-xl'>{selected}</p>

                                <div className='overflow-hidden mt-5 text-black dark:text-white text-base pb-10 max-w-full'>

                                    {type === 'video & text course' ?
                                        <div>
                                            <YouTube key={media} className='mb-5' videoId={media} opts={opts} />
                                            <StyledText text={theory} />
                                            <div className="flex justify-center items-center">

                                                <button
                                                    onClick={(event) => {
                                                        event.target.disabled = true;
                                                        downloadVideoCourse();
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black">{downloadText} </button>
                                                <button
                                                    onClick={(event) => {
                                                        setUserDropdown("visible")
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4">Share Course</button>
                                                <QuestionModal courseTopic={[courseTopic]} ></QuestionModal> <button
                                                    className="mx-2 delete-course-btn bg-red-500 hover:bg-red-700 text-white px-3 py-2 rounded font-medium focus:outline-none"
                                                    onClick={() => handleDeleteCourse()} // Replace with your deletion logic
                                                >
                                                    Delete Course
                                                </button>
                                                <AiModal courseTopic={[courseTopic]} />


                                                <div className=""></div>
                                            </div>
                                            <Select
                                                className={`${userDropdown} dropdown mt-2`}
                                                placeholder="Select Option"
                                                value={userData.filter(obj => selectedValue.includes(obj.value))} // set selected values
                                                options={userData} // set list of the data
                                                onChange={handleChange} // assign onChange function
                                                isMulti
                                                isClearable
                                            />
                                            <button
                                                onClick={(event) => {
                                                    submitUserDropDown()
                                                }}
                                                className={`${userDropdown} bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4 mt-2`}>Submit</button>
                                        </div>

                                        :
                                        <div>
                                            <StyledText text={theory} />
                                            <img className='overflow-hidden p-10' src={media} alt="Media" />
                                            <div className="flex justify-center items-center">
                                                <button
                                                    onClick={(event) => {
                                                        event.target.disabled = true;
                                                        downloadImageCourse();
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black">{downloadText}</button>
                                                <button
                                                    onClick={(event) => {
                                                        setUserDropdown("visible")
                                                    }}
                                                    className="bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4">Share Course</button>
                                                <QuestionModal courseTopic={courseTopic} ></QuestionModal>   <button
                                                    className="mx-2 delete-course-btn bg-red-500 hover:bg-red-700 text-white px-3 py-2 rounded font-medium focus:outline-none"
                                                    onClick={() => handleDeleteCourse()} // Replace with your deletion logic
                                                >
                                                    Delete Course
                                                </button>
                                                <AiModal />

                                                <div className=""></div>

                                            </div>
                                            <Select
                                                className={`${userDropdown} dropdown mt-2`}
                                                placeholder="Select Option"
                                                value={userData.filter(obj => selectedValue.includes(obj.value))} // set selected values
                                                options={userData} // set list of the data
                                                onChange={handleChange} // assign onChange function
                                                isMulti
                                                isClearable
                                            />
                                            <button
                                                onClick={(event) => {
                                                    submitUserDropDown()
                                                }}
                                                className={`${userDropdown} bg-black text-white px-3 py-2 font-medium dark:bg-white dark:text-black mx-4 mt-2`}>Submit</button>
                                            {/* <AiModal courseTopic={courseTopic} /> */}
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};


export default Course;
