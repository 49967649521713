import React, { useState, useMemo } from 'react';
import { FileText, Upload, X } from 'lucide-react';
import axios from 'axios';
import { serverURL } from '../constants';
import { toast } from 'react-toastify';
const MultipleFileUpload = ({ onFilesProcessed }) => {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const processFile = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);


            const response = await axios.post(
                `${serverURL}/api/extract-text`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            return {
                name: file.name,
                text: response.data.text,
                size: file.size
            };
        } catch (error) {
            console.error('Error processing file:', error);
            throw error;
        }
    };

    const handleFileChange = async (e) => {
        const newFiles = Array.from(e.target.files);
        setIsLoading(true);

        try {
            // Validate file types and sizes
            newFiles.forEach(file => {
              
                if (file.size > 10 * 1024 * 1024) { // 10MB limit
                    throw new Error('File size should be less than 10MB');
                }
            });

            const filePromises = newFiles.map(processFile);
            const processedFiles = await Promise.all(filePromises);

            setFiles(prev => [...prev, ...processedFiles]);
            onFilesProcessed(processedFiles.map(f => f.text).join('\n'));
        } catch (error) {
            toast.error(error.message || 'Error processing files');
        } finally {
            setIsLoading(false);
        }
    };

    const removeFile = (index) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        onFilesProcessed(updatedFiles.map(f => f.text).join('\n'));
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    return (
        <div className="w-full space-y-4">
            <div>
                <h3>Upload Study Materials (Optional):</h3>
                <p className="text-sm text-gray-500 mb-2">
                    Upload PDF files to generate questions based on your study materials
                </p>
            </div>

            <div className="flex items-center justify-center w-full">
                <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <Upload className="w-8 h-8 mb-2 text-gray-500" />
                        <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">Click to upload PDFs</span>
                        </p>
                        <p className="text-xs text-gray-500">PDF files (max 10MB each)</p>
                    </div>
                    <input
                        type="file"
                        className="hidden"
                        accept=".txt,.csv,.pdf,.log,.json,.xml,.html,.md,.ini,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        multiple
                        onChange={handleFileChange}
                        disabled={isLoading}
                    />
                </label>
            </div>

            {isLoading && (
                <div className="flex justify-center">
                    <div className="w-8 h-8 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
                </div>
            )}

            <div className="space-y-2">
                {files.map((file, index) => (
                    <div key={index} className="flex items-center justify-between p-2 bg-gray-50 rounded-lg">
                        <div className="flex items-center gap-2">
                            <FileText className="w-5 h-5 text-gray-500" />
                            <div>
                                <p className="text-sm font-medium">{file.name}</p>
                                <p className="text-xs text-gray-500">{formatFileSize(file.size)}</p>
                            </div>
                        </div>
                        <button
                            onClick={() => removeFile(index)}
                            className="p-1 hover:bg-gray-200 rounded-full"
                        >
                            <X className="w-4 h-4 text-gray-500" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MultipleFileUpload;