import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { serverURL } from '../constants';
import { Navbar } from 'flowbite-react';
import { FiMenu, FiX } from 'react-icons/fi';
import AdminSidebar from './components/adminsidebar';
import AdminHead from './components/adminhead';
import AdminSidebarMobile from './components/adminsidebarmobile';
import TermsEdit from './components/termsedit';

const CreateBlog = () => {
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [slug, setSlug] = useState('');

    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('darkMode', false);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(serverURL + '/api/blog', {
                title,
                content,
                image,
                slug,
                author: author, // You might want to get this from the logged-in user
                user: 'user_id_here', // You'll need to replace this with the actual user ID
            });
            navigate('/admin/blogs');
        } catch (error) {
            console.error('Error creating blog:', error);
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <div>
                <div className="flex bg-white dark:bg-black md:hidden pb-10 overflow-y-auto">
                    <div className={`fixed inset-0 bg-black opacity-50 z-50 ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
                    <div className="flex-1 flex flex-col overflow-hidden">
                        <div>
                            <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:text-white dark:border-white md:border-b'>
                                <Navbar.Brand className='ml-1'>
                                    <p className='font-black text-xl'>Admin Panel</p>
                                </Navbar.Brand>
                                <div className='flex md:hidden justify-center items-center'>
                                    {isSidebarOpen ? (
                                        <FiX
                                            onClick={toggleSidebar}
                                            className='mx-2'
                                            size={20}
                                            color={localStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                        />
                                    ) : (
                                        <FiMenu
                                            onClick={toggleSidebar}
                                            className='mx-2'
                                            size={20}
                                            color={localStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                        />
                                    )}
                                </div>
                            </Navbar>
                            <TermsEdit />
                        </div>
                        <AdminSidebarMobile isSidebarOpen={isSidebarOpen} />
                    </div>
                </div>
                <div className='flex flex-row overflow-y-auto h-screen max-md:hidden no-scrollbar'>
                    <AdminSidebar />
                    <div className='overflow-y-auto flex-grow flex-col dark:bg-black'>
                        <AdminHead />
                        <div className="container mx-auto px-4 py-8">
                            <h1 className="text-3xl font-bold mb-6">Create New Blog</h1>
                            <form onSubmit={handleSubmit} className="max-w-lg">
                                <div className="mb-4">
                                    <label htmlFor="title" className="block mb-2">Slug</label>
                                    <input
                                        type="text"
                                        id="slug"
                                        value={slug}
                                        onChange={(e) => setSlug(e.target.value)}
                                        required
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="title" className="block mb-2">Title</label>
                                    <input
                                        type="text"
                                        id="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        required
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="title" className="block mb-2">Author</label>
                                    <input
                                        type="text"
                                        id="author"
                                        value={author}
                                        onChange={(e) => setAuthor(e.target.value)}
                                        required
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="content" className="block mb-2">Content</label>
                                    <textarea
                                        id="content"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        required
                                        className="w-full p-2 border rounded h-40"
                                    ></textarea>
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="image" className="block mb-2">Image URL</label>
                                    <input
                                        type="text"
                                        id="image"
                                        value={image}
                                        onChange={(e) => setImage(e.target.value)}
                                        className="w-full p-2 border rounded"
                                    />
                                </div>
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                                    Create Blog
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateBlog;
