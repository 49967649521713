import React from 'react';
import Header from '../components/header';
import Footers from '../components/footers';

const TelegramTokenOnboarding = () => {
    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={false} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex items-center justify-center py-10 flex-col'>
                    <h1 className='text-center font-bold text-4xl text-black dark:text-white'>Telegram Token Generation</h1>
                    <div className='w-4/5 py-10 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg'>
                        <h2 className='text-2xl font-semibold text-black dark:text-white mb-4'>Onboarding Instructions for Telegram Token Generation</h2>

                        <div className='space-y-6'>
                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Step 1: Visit the Profile Page</h3>
                                <p className='text-gray-700 dark:text-gray-300'>
                                    Open your web browser and navigate to the <strong>Profile</strong> page:
                                    <a href="https://learnooza.com/profile" target="_blank" rel="noopener noreferrer" className='text-blue-500'>
                                        https://learnooza.com/profile
                                    </a>.
                                </p>
                            </div>

                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Step 2: Log In</h3>
                                <p className='text-gray-700 dark:text-gray-300'>
                                    If you are not already logged in, enter your credentials to access your profile.
                                </p>
                            </div>

                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Step 3: Update Your Profile (Optional)</h3>
                                <p className='text-gray-700 dark:text-gray-300'>
                                    Update your name, email, or password fields if needed. Click the <strong>Submit</strong> button to save changes.
                                </p>
                            </div>

                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Step 4: Generate a Telegram Token</h3>
                                <ol className='list-decimal pl-6 text-gray-700 dark:text-gray-300'>
                                    <li>Scroll to the <strong>Telegram Token</strong> section.</li>
                                    <li>
                                        Click the <strong>Generate</strong> button (green button) to create your unique Telegram API token.
                                        <ul className='list-inside'>
                                            <li>A token will appear in the input field labeled "Telegram Token."</li>
                                            <li>Example token: <code>learnooza_ai_bot_dryylox1u9</code>.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>

                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Step 5: Copy the Token</h3>
                                <p className='text-gray-700 dark:text-gray-300'>
                                    Click on the generated token or use the keyboard shortcut (e.g., <code>Ctrl + C</code> on Windows or <code>Cmd + C</code> on Mac) to copy it.
                                </p>
                            </div>

                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Step 6: Open Telegram</h3>
                                <ol className='list-decimal pl-6 text-gray-700 dark:text-gray-300'>
                                    <li>
                                        Download and install the Telegram app if you haven’t already:
                                        <ul className='list-inside'>
                                            <li>For Android: <a href="https://play.google.com/store/apps/details?id=org.telegram.messenger" target="_blank" rel="noopener noreferrer" className='text-blue-500'>Telegram on Google Play</a></li>
                                            <li>For iOS: <a href="https://apps.apple.com/app/telegram-messenger/id686449807" target="_blank" rel="noopener noreferrer" className='text-blue-500'>Telegram on the App Store</a></li>
                                            <li>For Desktop: <a href="https://desktop.telegram.org/" target="_blank" rel="noopener noreferrer" className='text-blue-500'>Telegram for Desktop</a></li>
                                        </ul>
                                    </li>
                                    <li>Log in to Telegram or create an account if you’re a new user.</li>
                                </ol>
                            </div>

                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Step 7: Send the Token</h3>
                                <ol className='list-decimal pl-6 text-gray-700 dark:text-gray-300'>
                                    <li>Open the chat with the bot or admin where the token needs to be sent.</li>
                                    <li>Paste the copied token into the chat.</li>
                                    <li>Send the token.</li>
                                </ol>
                            </div>

                            <div>
                                <h3 className='text-xl font-semibold text-black dark:text-white'>Additional Features on the Profile Page</h3>
                                <ul className='list-inside text-gray-700 dark:text-gray-300'>
                                    <li><strong>Regenerate Token (Blue Button):</strong> If your current token is compromised or needs renewal, click this to create a new token.</li>
                                    <li><strong>Delete Token (Red Button):</strong> If you want to remove the token, click this button.</li>
                                    <li><strong>Manage Subscriptions:</strong> Use this option to configure or manage your subscription settings.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default TelegramTokenOnboarding;
