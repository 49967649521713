import React, { useEffect, useState } from 'react';
import { messaging } from '../firebase';
import { getToken } from 'firebase/messaging';
import { publicVapidKey } from '../constants';
import { serverURL } from '../constants';
import axios from 'axios';
const PushNotificationButton = () => {
    const [isTokenFound, setTokenFound] = useState(false);
    const [token, setToken] = useState('');

    useEffect(() => {
        const vapidKey = publicVapidKey;

        const requestNotificationPermission = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const currentToken = await getToken(messaging, { vapidKey });

                    if (currentToken) {
                        const user_id = localStorage.getItem('uid'); // Get user ID from session storage

                        // Send the token and user ID to your server
                        await axios.post(serverURL + '/api/saveSubscription', { token: currentToken, user_id });
                        return true;
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                } else {
                    console.log('Unable to get permission to notify.');
                }
            } catch (err) {
                console.error('An error occurred while retrieving token: ', err);
            }
        };

        requestNotificationPermission();
    }, []);




};

export default PushNotificationButton;