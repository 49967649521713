import React, { useState } from 'react';
import { Plus, X } from 'lucide-react';

const StudyTimePreference = ({ onPreferenceChange }) => {
    const [preferenceType, setPreferenceType] = useState('general');
    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const [timeBlocks, setTimeBlocks] = useState([{ startTime: '', endTime: '' }]);

    const periods = ['Morning', 'Afternoon', 'Evening', 'Night'];

    const handlePeriodChange = (period) => {
        const updated = selectedPeriods.includes(period)
            ? selectedPeriods.filter(p => p !== period)
            : [...selectedPeriods, period];
        setSelectedPeriods(updated);
        onPreferenceChange({
            type: 'general',
            value: updated
        });
    };

    const handleTimeBlockChange = (index, field, value) => {
        const updatedBlocks = [...timeBlocks];
        updatedBlocks[index][field] = value;
        setTimeBlocks(updatedBlocks);
        onPreferenceChange({
            type: 'specific',
            value: updatedBlocks
        });
    };

    const addTimeBlock = () => {
        setTimeBlocks([...timeBlocks, { startTime: '', endTime: '' }]);
    };

    const removeTimeBlock = (index) => {
        const updatedBlocks = timeBlocks.filter((_, i) => i !== index);
        setTimeBlocks(updatedBlocks);
        onPreferenceChange({
            type: 'specific',
            value: updatedBlocks
        });
    };

    return (
        <div className="space-y-4">
            <div className="flex gap-4 mb-4">
                <label className="flex items-center">
                    <input
                        type="radio"
                        checked={preferenceType === 'general'}
                        onChange={() => {
                            setPreferenceType('general');
                            onPreferenceChange({
                                type: 'general',
                                value: selectedPeriods
                            });
                        }}
                        className="mr-2"
                    />
                    General time periods
                </label>
                <label className="flex items-center">
                    <input
                        type="radio"
                        checked={preferenceType === 'specific'}
                        onChange={() => {
                            setPreferenceType('specific');
                            onPreferenceChange({
                                type: 'specific',
                                value: timeBlocks
                            });
                        }}
                        className="mr-2"
                    />
                    Specific time blocks
                </label>
            </div>

            {preferenceType === 'general' ? (
                <div className="grid grid-cols-2 gap-2">
                    {periods.map((period) => (
                        <label key={period} className="flex items-center p-2 border rounded hover:bg-gray-50">
                            <input
                                type="checkbox"
                                checked={selectedPeriods.includes(period)}
                                onChange={() => handlePeriodChange(period)}
                                className="mr-2"
                            />
                            {period}
                        </label>
                    ))}
                </div>
            ) : (
                <div className="space-y-4">
                    {timeBlocks.map((block, index) => (
                        <div key={index} className="flex items-center gap-4">
                            <input
                                type="time"
                                value={block.startTime}
                                onChange={(e) => handleTimeBlockChange(index, 'startTime', e.target.value)}
                                className="flex-1 p-2 border rounded"
                            />
                            <span className="text-gray-500">to</span>
                            <input
                                type="time"
                                value={block.endTime}
                                onChange={(e) => handleTimeBlockChange(index, 'endTime', e.target.value)}
                                className="flex-1 p-2 border rounded"
                            />
                            {timeBlocks.length > 1 && (
                                <button
                                    onClick={() => removeTimeBlock(index)}
                                    className="p-1 text-red-500 hover:text-red-700"
                                >
                                    <X className="w-4 h-4" />
                                </button>
                            )}
                        </div>
                    ))}
                    <button
                        onClick={addTimeBlock}
                        className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                    >
                        <Plus className="w-4 h-4" />
                        <span>Add Time Block</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default StudyTimePreference;